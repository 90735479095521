/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  background: none;
  color: #444;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-bottom: 15px;
  width: 100%;
  color: #212529;
  font-size: 0.9rem;
  background-color: #f7f7f7;
  p {
    margin: 0 0 5px 0;
  }
  a {
    color: #212529;
  }
  #trustwaveSealImage {
    float: left;
    margin: 0 10px 30px 0;
  }
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header {
  float: none;
  a.logo {
    float: left;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 0;
    width: 75%;
    max-width: 400px;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.help-center {
      // display: inline-block;
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        color: $theme-secondary;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
  }
}

#header-wrap {
  background: rgba(255, 255, 255, 0.75);
}

#banner-wrap {
  &.home {
    background: #f4fcf1 url(../../images/banners/home-banner-sm.jpg) no-repeat 50% 25% / cover;
  }
  &.two-15 {
    background: #f4fcf1 url(../../images/banners/2-15-banner-sm.jpg) no-repeat 50% 20% / cover;
  }
}

p.headline {
  color: $theme-secondary;
}
p.red {
  color: #e63946;
}
span.red {
  color: #e63946;
}

.card-header {
  &.silver {
    border-bottom: 4px solid #c0c0c0 !important;
  }

  &.gold {
    border-bottom: 4px solid #d2be69 !important;
  }

  &.platinum {
    border-bottom: 4px solid #5ecef3 !important;
  }
}
.card {
  .medal div {
    border-bottom: 1px solid;
    height: 2px;
    width: 150px;
    &.silver {
      border-color: #c0c0c0;
    }

    &.gold {
      border-color: #d2be69;
    }

    &.platinum {
      border-color: #5ecef3;
    }
  }
}

iframe[name='main'] {
  border: 1px solid #000;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #banner-wrap {
    &.home {
      background: #f4fcf1 url(../../images/banners/home-banner.jpg) no-repeat 50% 20% / cover;
    }
    &.two-15 {
      background: #f4fcf1 url(../../images/banners/2-15-banner.jpg) no-repeat 50% 15% / cover;
    }
    .details {
      max-width: 375px;
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #banner-wrap {
    .details {
      max-width: inherit;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header {
    a.logo {
      width: 45%;
      max-width: 340px;
    }
  }
}
