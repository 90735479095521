/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
$blue: #316ce8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #fb4f16 !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #36b9cc !default;
$cyan: #17a2b8 !default;
$gray-600: #6c757d !default;

/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;

/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
$theme-primary: #1d3557 !default;
$theme-secondary: #2e6a9d !default;
$theme-nav-primary: $theme-primary !default;
$theme-nav-secondary: $theme-secondary !default;
$link-color: $blue !default;

/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
$cta-red: #f43325 !default;
$cta-orange: #ff571f !default;
$cta-green: #49a752 !default;
$cta-blue: #0087e0 !default;
$cta-yellow: #ffc107 !default;
$cta-dark: #555555 !default;

/* ----------------------------------------------------------------------------
  Text colors
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: $theme-primary !important;
}
.text-theme-secondary {
  color: $theme-secondary !important;
}

/* ----------------------------------------------------------------------------
  Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: $theme-primary !important;
}
.bg-theme-secondary {
  background-color: $theme-secondary !important;
}
.bg-theme-gradient {
  background: linear-gradient(30deg, #3f4f8e, #6f7eb9 42%, #a5add1);
}
.bg-green {
  background: #f4fcf1;
  border-top: 1px solid #def7d4;
  border-bottom: 1px solid #def7d4;
}
.bg-grey-light {
  background: #f6f9fc;
}
.bg-grey-med {
  background: #e9ecef;
}
.bg-theme-dark {
  background: #303444;
}
/* ----------------------------------------------------------------------------
  Borders
----------------------------------------------------------------------------- */
.border-theme-primary {
  border-color: $theme-primary !important;
}
